import _ from "lodash"
import React, { useState } from "react"
import { useEffect } from "react"
import Autosuggest from "react-autosuggest"

const getSuggestionValue = suggestion => suggestion.name

const renderSuggestion = suggestion => <div>{suggestion.name}</div>

const SearchAutoSuggest = ({
  handleSelectArea,
  areasSuggestionList = [],
  placeholder,
  setSearchText,
  searchText,
  setSearchSlug,
}) => {
  const [suggestions, setSuggestions] = useState([])

  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    return inputLength === 0
      ? []
      : areasSuggestionList
          .filter(
            lang => lang.name.toLowerCase().slice(0, inputLength) === inputValue
          )
          .slice(0, 10)
  }

  const onChange = (_, { newValue }) => {
    setSearchText(newValue)
    setSearchSlug(newValue?.replace(" ","-").toLowerCase(), false)
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value))
  }

  const onSuggestionSelected = (_, { suggestion }) => {
    handleSelectArea(suggestion)
    // setValue("")
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const changesearchText = text => {
    return text.toLowerCase().replace( /\b./g, function(a){ return a.toUpperCase(); } ).replace("-"," ");
  }
  const inputProps = {
    placeholder,
    value: changesearchText(searchText),
    onChange: onChange,
  }

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      onSuggestionSelected={onSuggestionSelected}
      inputProps={inputProps}
    />
  )
}

export default SearchAutoSuggest
