import clsx from "clsx"
import React from "react"
import { parseHTML } from "../../common/utils/utils"
import PlayVideoButton from "../PlayVideo/PlayVideoButton"
import Animation from "../elements/Animation"
import CTALink from "../elements/CTALink"
import GGFXImage from "../elements/GGFXImage"
import HalfContainer from "../elements/HalfContainer"
import "./TileBlockFull.scss"

const TileBlockFull = ({ module, pageData }) => {
  if (!module || !pageData) return null

  const { bg_color, content, cta, id, image, title, video_url, image_align } =
    module

  const { strapi_id, imagetransforms } = pageData

  let processedImages = imagetransforms?.image_Transforms
  processedImages ||= JSON.stringify({})

  return (
    <div
      className={clsx("tile-block-full-wrap", `bg-${bg_color}, ${image_align}`)}
    >
      <Animation className="image-section">
        <GGFXImage
          ImageSrc={image}
          title={title?.data?.title}
          altText={image?.alternativeText}
          imagetransforms={processedImages}
          renderer="srcSet"
          imagename={"page.image.tile_image_full"}
          strapi_id={strapi_id}
          className={"image"}
        />
        <PlayVideoButton videoUrl={video_url} />
      </Animation>
      <HalfContainer paddingLeft className="tile-block-container">
        <Animation animateInType={"up"} className="content-section">
          <h2 className="tilte">{parseHTML(title.data.title)}</h2>
          <div className="description">{parseHTML(content.data.content)}</div>
          {cta?.cta_label && cta?.menu && (
            <div className="cta-section">
              <CTALink cta={cta} className={"button button-green-outline"} />
            </div>
          )}
        </Animation>
      </HalfContainer>
    </div>
  )
}

export default TileBlockFull
