import _ from "lodash"
import React, { useEffect, useState } from "react"
import { Container, Tab, Tabs } from "react-bootstrap"
import { getStrapiDataFromURL, parseHTML } from "../../common/utils/utils"
import CustomSlider from "../CustomSlider/CustomSlider"
import PropertyCard from "../PropertyCard/PropertyCard"
import Animation from "../elements/Animation"
import { Link } from "gatsby"
import "./AreaGuideSoldProperties.scss"
const KEYS = {
  sale: "for-sale",
  rent: "to-rent",
}

const AreaGuideSoldProperties = props => {
  const [saleList, setSaleLists] = useState([])
  const [rentList, setRentLists] = useState([])
  const [key, setKey] = useState(KEYS.sale)

  const getitemsSale = async url => {
    var areaSaleLists = await getStrapiDataFromURL(url)
    if (areaSaleLists.data) {
      areaSaleLists.data.sort((a, b) => a.attributes.status.localeCompare(b.attributes.status));
    }
    setSaleLists(areaSaleLists)
    // console.log('checkthedata', areaSaleLists)
  }
  const getitemslet = async url => {
    var areaLetLists = await getStrapiDataFromURL(url)
    if (areaLetLists.data) {
      areaLetLists.data.sort((a, b) => a.attributes.status.localeCompare(b.attributes.status));
    }
    setRentLists(areaLetLists)
  }

  useEffect(() => {
    let area = props.PageData?.name ? props.PageData?.name : ""
    let saleUrl = `/api/properties?filters[search_type][$eq]=sales&filters[department][$eq]=residential&filters[publish][$eq]=true&pagination[page]=1&pagination[pageSize]=6&sort=updatedAt:desc`
    if (area) {
      saleUrl = saleUrl + "&filters[area][$eq]=" + area
    }
    if(props.filter){
      saleUrl = `${saleUrl}&${props.filter}`;
    }
    let url_let = `/api/properties?filters[search_type][$eq]=lettings&filters[department][$eq]=residential&filters[publish][$eq]=true&pagination[page]=1&pagination[pageSize]=6&sort=updatedAt:desc` // TODO: base URL supposed to be from .env
    if (area) {
      url_let = url_let + "&filters[area][$eq]=" + area
    }
    if(props.filter){
      url_let = `${url_let}&${props.filter}`;
    }

    getitemsSale(saleUrl)
    getitemslet(url_let)
  }, [])

  const SliderTab = ({ items }) => {
    if (_.isEmpty(items)) return null

    return (
      <CustomSlider className="areaguide-slider-wrap">
        {items.data.map(property => (
          <PropertyCard
            address={property.attributes?.display_address}
            description={property.attributes?.title}
            department={property.attributes?.department}
            image={property.attributes?.images[0]}
            price={property.attributes?.price}
            slug={property.attributes?.slug}
            imagetransforms={property.attributes?.imagetransforms}
            id={property?.attributes?.crm_id}
            pid={property?.id}
            strapi_id={property.id}
            search_type={property.attributes?.search_type}
            status={property.attributes?.status}
            bedroom={property.attributes?.bedroom}
            bathroom={property.attributes?.bathroom}
            floorarea_max={property.attributes?.floorarea_max}
            floorarea_type={property.attributes?.floorarea_type}
            reception={property.attributes?.reception}
            price_qualifier={property.attributes?.price_qualifier}
            slider_name="area-guide-slider"
          />
        ))}
      </CustomSlider>
    )
  }

  return rentList?.data?.length > 0 || saleList?.data?.length > 0 ? (
    <Animation animateInType={"up"} className="areaguide-properties-section">
      <Container className="areaguide-properties-container">
        <h2 className="title">{parseHTML(props?.title)}</h2>
        <Tabs
          activeKey={key}
          onSelect={k => setKey(k)}
          className="areaguide-properties-tab-section"
        >
          {saleList?.data?.length > 0 && (
            <Tab eventKey={KEYS.sale} title="property for sale">
              <SliderTab items={saleList} />
              {props?.SaleURL && (
                <div className="ctas d-flex justify-content-center">
                  <Link class="button button-green" to={props.SaleURL} >
                    <span>view More properties</span>
                  </Link>
                </div>
              )}
            </Tab>
          )}
          {rentList?.data?.length > 0 && (
            <Tab eventKey={KEYS.rent} title="property to rent">
              <SliderTab items={rentList} />
              {props?.RentURL && (
                <div className="ctas d-flex justify-content-center">
                  <Link class="button button-green" to={props.RentURL} >
                    <span>view More properties</span>
                  </Link>
                </div>
              )}
            </Tab>
          )}
        </Tabs>
      </Container>
    </Animation>
  ) : null
}
export default AreaGuideSoldProperties
