import { SaveItem } from "@starberry/myaccount-website-utils"
import { Link } from "gatsby"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { connectSortBy, connectStats } from "react-instantsearch-dom"
import { contactURL } from "../../common/utils/urls"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import locationIcon from "../../images/icons/MapPin.svg"
import infoIcon from "../../images/icons/information-circle.svg"
import SEO from "../SEO/seo"
import { parseHTML } from "../../common/utils/utils"
import Animation from "../elements/Animation"
import CheckBox from "../elements/CheckBox"
import CustomSelect from "../elements/CustomSelect/CustomSelect"
import HeartIcon from "../elements/HeartIcon"
import { usePropertyStore } from "./store/propertyStore"
import {
  INDEX_NAME,
  getH1Text,
  getSeoDesc,
  sortOptions,
  stateToParams,
} from "./utils"

const SortBySelect = connectSortBy(({ items, refine, currentRefinement }) => (
  <div className="filter">
    <CustomSelect
      className={"sort-select"}
      options={items}
      value={items.find(item => item.value === currentRefinement) || items[0]}
      onChange={option => {
        refine(option.value, option.label)
      }}
    />
  </div>
))

export const IncludeToggle = ({ Sold, setSold }) => {
  const [addStatus, removeStatus, includeSold, setIncludeSold, pType] =
    usePropertyStore(state => [
      state.addStatus,
      state.removeStatus,
      Sold ? Sold : state.includeSold,
      Sold ? setSold : state.setIncludeSold,
      state.pType,
    ])
  const isSales = pType === "sales"

  useEffect(() => {
    if (includeSold) {
      addStatus(
        isSales ? ["Sold", "Sold Subject To Contract"] : ["Let", "Let Agreed"]
      )
    } else {
      removeStatus(
        isSales ? ["Sold", "Sold Subject To Contract"] : ["Let", "Let Agreed"]
      )
    }
  }, [includeSold])

  return (
    <CheckBox
      className={"filter"}
      label={`Include ${isSales ? "Sold" : "Let"}`}
      checked={Sold ? Sold : includeSold}
      setChecked={Sold ? setSold : setIncludeSold}
    />
  )
}

const H1Filters = ({
  savedSearchParams,
  userObjects,
  nbHits,
  handleMapScroll,
}) => {
  const [showDesc, setShowDesc] = useState(false)

  const { isTablet } = useDeviceMedia()

  const showMap = usePropertyStore(state => state.showMap)
  const setShowMap = usePropertyStore(state => state.setShowMap)
  const searchState = usePropertyStore(state => state.searchState)
  const pType = usePropertyStore(state => state.pType)
  const pDepartment = usePropertyStore(state => state.pDepartment)

  const isResidential = pDepartment === "residential"
  const isCommercial = pDepartment === "commercial"
  const isNewHomes = pDepartment === "new_developments"
  const [Sold, setSold] = useState(pDepartment === "commercial" || pDepartment === "new_developments")
  let path =
    typeof window !== "undefined" ? window.location.pathname?.split("/") : ""
  const newDevelopments =
    path[1] === "new-developments" ? "secondary-newhomes" : ""

  const h1Text = getH1Text({
    searchState,
    pType,
    capitalize: true,
    isResidential,
    isCommercial,
    isNewHomes,
    newDevelopments,
  })
  const infoText = getH1Text({
    searchState,
    pType,
    capitalize: false,
    isResidential,
    isCommercial,
    isNewHomes,
    newDevelopments,
  })
  const seoDescription = getSeoDesc({
    searchState,
    pType,
    isResidential,
    isCommercial,
    isNewHomes,
    newDevelopments,
  })

  const algoliaState = usePropertyStore(state => state.searchState)
  const state = usePropertyStore(state => state)
  const params = stateToParams(state, algoliaState)
  const saveSearchParams = !_.isEmpty(params) && savedSearchParams(params)
  const stype = saveSearchParams.search_type == 'sales' ? "for sale" : "to rent";
  const atype = saveSearchParams.search_type == 'sales' ? "estate" : "letting";

  const content =
  typeof window !== "undefined" && window.location.pathname.includes("/properties/for-sale/in-canterbury/")
    ? `Explore our latest properties ${stype} in Canterbury, carefully selected by our experienced <a href="/estate-agents-in-canterbury/">${atype} agents in Canterbury</a>. With Canterbury’s rich history, excellent schools, and great transport links, now is the perfect time to find your ideal property.`
    : typeof window !== "undefined" && window.location.pathname.includes("/properties/for-sale/in-faversham/")
    ? `Our expert <a href="/estate-agents-in-faversham/">${atype} agents in Faversham</a> are here to help you find the perfect home in this historic and vibrant market town. Explore our latest properties ${stype} in Faversham, from charming period houses to contemporary waterside homes in prime locations. With excellent schools, great transport links, and a thriving community, Faversham offers a fantastic place to call home.`
    : `Explore our latest ${infoText}, carefully selected by our experienced estate agents in ${params.areas}. With ${params.areas}’s rich history, excellent schools, and great transport links, now is the perfect time to find your ideal property.`;
  
    let finalInfoText = (
    <>
      {parseHTML(content)}
    </>
  )

  const handleMap = () => {
    setShowMap(!showMap)
    handleMapScroll()
  }

  return (
    <Animation animat eInType={"up"} className="h1-and-filters-section">
      <SEO title={h1Text} description={seoDescription} />
      <div className="h1-section-wrap">
        <div className="h1-section">
          <h1>
            {h1Text} <b>({nbHits})</b>
          </h1>
          {!!nbHits && (
            <img
              src={infoIcon}
              alt="info icon"
              className="info-icon"
              onClick={() => setShowDesc(!showDesc)}
            />
          )}
        </div>
        {showDesc && <p className="h1-description">{finalInfoText}</p>}
      </div>
      {/* {!!nbHits && ( */}
      <div className="filters-section">
        {!isTablet && (
          <>
            <div className="filter">
              <SaveItem
                type="search"
                searchParams={saveSearchParams}
                userObjects={userObjects}
              >
                <HeartIcon className={"filter-icon"} />
                <p className="filter-label">Save Search</p>
              </SaveItem>
            </div>
            <div className="divider"></div>
            <IncludeToggle Sold={Sold} setSold={setSold} />
            <div className="divider"></div>
          </>
        )}
        {!!nbHits && (
          <>
            <div className="filter" onClick={() => handleMap()}>
              <img src={locationIcon} alt="info icon" className="filter-icon" />
              <p className="filter-label">Map View</p>
            </div>
            <div className="divider"></div>
            <SortBySelect defaultRefinement={INDEX_NAME} items={sortOptions} />
          </>
        )}
      </div>
      {/* )} */}
    </Animation>
  )
}

const H1_Filters = connectStats(H1Filters)

export default H1_Filters
