import loadable from "@loadable/component"
import clsx from "clsx"
import { graphql, Link } from "gatsby"
import React, { useEffect, useState } from "react"
import Layout from "../components/Layout/Layout"
import { Helmet } from "react-helmet"
// import Seo from "../components/seo"
import _ from "lodash"
import { Container } from "react-bootstrap"
import { aboutURL, propertiesRentURL, propertiesSaleURL } from "../common/utils/urls"
import { parseHTML, getButtonClass } from "../common/utils/utils"
import HelpBlock from "../components/HelpBlock/HelpBlock"
import AppraisalComponent from "../components/AppraisalComponent/AppraisalComponent"
import ReviewsSlider from "../components/ReviewsSlider/ReviewsSlider"
import AreaGuide from "../components/Office/AreaGuide"
import AreaGuideSoldProperties from "../components/AreaGuideSoldProperties/AreaGuideSoldProperties"
import NegoCard from "../components/NegoCard/NegoCard"
import SEO from "../components/SEO/seo"
import ShareIcons from "../components/SocialShare/ShareIcons"
import Animation from "../components/elements/Animation"
import GGFXImage from "../components/elements/GGFXImage"
import LocRatingOfficeMap from "../components/map/loc-rating-map-office"
import OfficeBanner from "../components/Office/Banner"
import { MapMarkerInfoBox } from "../components/map/map-marker"
import CTALink from "../components/elements/CTALink"
import wordsToNumbers from "words-to-numbers"
import useDeviceMedia from "../hooks/useDeviceMedia"
import "../styles/other/OfficeDetailTemplate.scss"
const Slider = loadable(() => import("react-slick"))
const OfficeDetail = ({ data }, props) => {
  const { isTablet, isMobile } = useDeviceMedia()
  const PageData = data?.strapiOffice;
  const banner = PageData?.banner;
  const opening_hours = PageData?.opening_hours;
  const office_ctas = PageData?.office_ctas;
  const area_guide = PageData?.area_guide;
  const help_block = PageData?.help_block;

  let testimonials = data?.greviews?.reviews
  var testimonial_count = testimonials ? testimonials.length : 0
  var rating_count = 0
  testimonials &&
    testimonials.length > 0 &&
    testimonials.map((item, ind) => {
      rating_count += parseInt(wordsToNumbers(item.starRating))
    })
  var rating_avg = rating_count / testimonial_count
  console.log('PageData', PageData)

  let mapItems = []
  let mapItem = {}
  mapItem["lat"] = PageData?.latitude
  mapItem["lng"] = PageData?.longitude
  const mapMarkerImageShowFlag = 0
  let mapItemHtml = MapMarkerInfoBox("test", mapMarkerImageShowFlag)
  mapItem["html"] = mapItemHtml
  mapItems.push(mapItem)
  const metaTitle = `${PageData?.meta_title}`
  const metaDescription = `${PageData?.meta_description}`
  const breadcrumbURLs = [
    {
      url: `${PageData?.slug}/`,
      label: PageData?.title,
    }
  ];

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 420) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={clsx("office-template areaguide-template")}>
      <Layout popular_search={{ title: "category1" }} pageData={{ layout: 'landing_page' }}> 
        <SEO title={metaTitle} description={metaDescription} />

        <OfficeBanner
          data={banner}
          pageData={PageData}
          breadcrumbURLs={breadcrumbURLs}
          transparentHeader={true}
          rating_avg={rating_avg}
          testimonial_count={testimonial_count}
        />
        <div className="areaguide-page-block">
          <Container className="content-section">
            <div className="left-section">
              {PageData.Content &&
                <>
                  <Animation animateInType={"up"} className="content-text">
                    {parseHTML(PageData.Content.data.Content)}
                    <div className="ctas">
                      <Link
                        class="button button-green"
                        to={`${propertiesSaleURL}in-${PageData?.location_slug}/`}
                      >
                        <span>Property for Sale in {PageData?.location}</span>
                      </Link>
                      <Link
                        class="button button-green-outline"
                        to={`${propertiesRentURL}in-${PageData?.location_slug}/`}
                      >
                        <span>Property to Rent in {PageData?.location}</span>
                      </Link>
                    </div>
                  </Animation>
                </>
              }
            </div>
            <div className="right-section">
              <div className="sticky-section">
                <div className="office-info">
                  {PageData.teams && PageData.teams.map((team, k) => (
                    <NegoCard team={team} key={k} isBottomLine={true} sales_contact={PageData.sales_contact} letting_contact={PageData.letting_contact} />
                  ))}
                  {PageData?.areas_covered?.data?.areas_covered && (
                    <div class="areas-covered">
                      <h5>Areas Covered</h5>
                      {parseHTML(PageData.areas_covered.data.areas_covered)}
                    </div>
                  )}
                  <span className="hr-line"></span>
                  <div className="office-hours">
                    <h5>Opening Hours</h5>
                    {opening_hours && opening_hours.map((opening, k) => {
                      return (
                        <div key={k}>
                          <p><span className="day">{opening.day}:</span> {opening.timing}</p>
                        </div>
                      )
                    })}

                  </div>

                  {office_ctas && (
                    <div className="cta-section">
                      {office_ctas?.map((cta, index) => (
                        <CTALink
                          cta={cta}
                          className={`${getButtonClass(cta.cta_theme)} ${cta.cta_label === "Book a Valuation" ? "book_val_but" : ""
                            }`}
                        />
                      ))}
                    </div>
                  )}

                </div>
              </div>
            </div>
          </Container>
          {PageData?.latitude && PageData?.longitude && (
            <Animation className="area-guide-map">
              <LocRatingOfficeMap data={mapItems} />
            </Animation>
          )}
          <AreaGuideSoldProperties
            title={`Find <i>your home</i> in ${PageData?.location}`}
            PageData={null}
            filter={PageData.property_search_filter}
            SaleURL={`${propertiesSaleURL}in-${PageData?.location_slug}/`}
            RentURL={`${propertiesRentURL}in-${PageData?.location_slug}/`}
          />
          <AreaGuide module={area_guide} pageData={PageData} />
          {PageData?.market_property_data?.data?.market_property_data && (
            <div className="market-property-data">
              <div class="container">
                <h2 className="title">
                  {PageData?.location} <i>Market Property</i> Data
                </h2>
                <div class="row text-center">
                  {parseHTML(PageData.market_property_data.data.market_property_data)}
                </div>
              </div>
            </div>
          )}
          <ReviewsSlider />
          <HelpBlock {...help_block} />
          {isMobile ?
            <div className="floating-ctas"
              style={{
                display: scrolled ? 'grid' : 'none'
              }}
            >
              <a href="/property-valuation/home-valuation" className="button button-green">
                <span>Sales Valuation</span>
              </a>
              <a href="/free-rental-property-valuation" className="button button-green-outline">
                <span>Rental Valuation</span>
              </a>
            </div>
          : null}
        </div>

      </Layout>
    </div>
  )
}

export default OfficeDetail

export const query = graphql`
  query ($page_id: String) {
    strapiOffice(id: { eq: $page_id }) {
      ...OfficeFragment     
    
    }
    greviews(reviews: {elemMatch: {starRating: {eq: "FIVE"}}}) {
      reviews {
        id
        name
        comment
        starRating
        reviewer {
          displayName
          profilePhotoUrl
        }
      }
    }
  }
`
